import React from 'react'
import { FiUpload, FiVideo } from 'react-icons/fi'

function CourseBasicInfo({ courseData, handleInputChange, handleFileUpload }) {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Course Thumbnail</label>
          <div className="border-2 border-dashed border-gray-300 rounded-md p-6 flex flex-col items-center">
            {courseData.courseImage ? (
              <img src={URL.createObjectURL(courseData.courseImage)} alt="Thumbnail" className="w-full h-48 object-cover" />
            ) : (
              <>
                <FiUpload className="text-4xl text-gray-400 mb-2" />
                <p className="text-sm text-gray-500">Upload an image</p>
                <p className="text-xs text-gray-400 mt-1">or drag and drop here</p>
              </>
            )}
            <input 
              type="file" 
              accept="image/*" 
              onChange={(e) => handleFileUpload(e, 'courseImage')} 
              className="hidden" 
              id="thumbnail-upload"
            />
            <label 
              htmlFor="thumbnail-upload" 
              className="mt-4 cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Select File
            </label>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Promotional Video</label>
          <div className="border-2 border-dashed border-gray-300 rounded-md p-6 flex flex-col items-center">
            {courseData.courseFile ? (
              <video src={URL.createObjectURL(courseData.courseFile)} controls className="w-full h-48 object-cover" />
            ) : (
              <>
                <FiVideo className="text-4xl text-gray-400 mb-2" />
                <p className="text-sm text-gray-500">Upload a video</p>
                <p className="text-xs text-gray-400 mt-1">or drag and drop here</p>
              </>
            )}
            <input 
              type="file" 
              accept="video/*" 
              onChange={(e) => handleFileUpload(e, 'courseFile')} 
              className="hidden" 
              id="video-upload"
            />
            <label 
              htmlFor="video-upload" 
              className="mt-4 cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Select File
            </label>
          </div>
        </div>
      </div>

      <div>
        <label htmlFor="courseName" className="block text-sm font-medium text-gray-700 mb-2">Course Title</label>
        <input 
          type="text" 
          id="courseName" 
          name="courseName" 
          value={courseData.courseName} 
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          placeholder="Enter course title"
        />
      </div>

      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-2">Course Subtitle</label>
        <input 
          type="text" 
          id="description" 
          name="description" 
          value={courseData.description} 
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          placeholder="Enter course subtitle"
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <label htmlFor="courseLanguage" className="block text-sm font-medium text-gray-700 mb-2">Course Language</label>
          <select 
            id="courseLanguage" 
            name="courseLanguage" 
            value={courseData.courseLanguage} 
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          >
            <option value="">Select Language</option>
            <option value="english">English</option>
            <option value="arabic">Arabic</option>
          </select>
        </div>
        <div>
          <label htmlFor="grade" className="block text-sm font-medium text-gray-700 mb-2">Grade</label>
          <select 
            id="grade" 
            name="grade" 
            value={courseData.grade} 
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          >
            <option value="">Select Grade</option>
            <option value="1">Grade 1</option>
            <option value="2">Grade 2</option>
            {/* Add more grade options as needed */}
          </select>
        </div>
        <div>
          <label htmlFor="semester" className="block text-sm font-medium text-gray-700 mb-2">Semester</label>
          <select 
            id="semester" 
            name="semester" 
            value={courseData.semester} 
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          >
            <option value="">Select Semester</option>
            <option value="first semester">First Semester</option>
            <option value="second semester">Second Semester</option>
          </select>
        </div>
      </div>

      <div>
        <label htmlFor="fullDescription" className="block text-sm font-medium text-gray-700 mb-2">Course description</label>
        <textarea 
          id="fullDescription" 
          name="fullDescription" 
          value={courseData.fullDescription} 
          onChange={handleInputChange}
          rows="4"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          placeholder="Enter full course description"
        ></textarea>
      </div>
    </div>
  )
}

export default CourseBasicInfo
