import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { addCourse } from '../api'
import { fetchCoursesByTeacherId } from '../store/coursesSlice'
import CourseBasicInfo from './CourseSetup/CourseBasicInfo'
import Curriculum from './CourseSetup/Curriculum'
import Pricing from './CourseSetup/Pricing'
import Promotions from './CourseSetup/Promotions'
import CourseMessage from './CourseSetup/CourseMessage'

const steps = ['Course setup', 'Curriculum', 'Pricing', 'Promotions', 'Course message']

function CourseSetup() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const [currentStep, setCurrentStep] = useState(0)
  const [courseData, setCourseData] = useState({
    subjectId: '',
    courseImage: null,
    courseName: '',
    courseFile: null,
    grade: '',
    semester: '',
    courseLanguage: '',
    description: '',
    price: '',
    discount_price: '',
    you_will_get: '',
    edus_fee: '',
    curriculum: [],
    promotions: [],
    courseMessage: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setCourseData(prev => ({ ...prev, [name]: value }))
  }

  const handleFileUpload = (e, type) => {
    const file = e.target.files[0]
    setCourseData(prev => ({ ...prev, [type]: file }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setStatus(null)
    
    const formData = new FormData()
    formData.append('subjectId', courseData.subjectId)
    formData.append('teacherId', user.teacherId)
    formData.append('courseImage', courseData.courseImage)
    formData.append('courseName', courseData.courseName)
    formData.append('courseFile', courseData.courseFile)
    formData.append('grade', courseData.grade)
    formData.append('semester', courseData.semester)
    formData.append('courseLanguage', courseData.courseLanguage)
    formData.append('description', courseData.description)
    formData.append('language', 'EN') // You might want to make this dynamic
    formData.append('token', user.token)
    formData.append('price', courseData.price)
    formData.append('discount_price', courseData.discount_price)
    formData.append('you_will_get', courseData.you_will_get)
    formData.append('edus_fee', courseData.edus_fee)
    formData.append('curriculum', JSON.stringify(courseData.curriculum))
    formData.append('promotions', JSON.stringify(courseData.promotions))
    formData.append('courseMessage', courseData.courseMessage)

    try {
      const response = await addCourse(formData)
      console.log('Course added successfully:', response)
      setStatus({ type: 'success', message: 'Course added successfully!' })
      
      dispatch(fetchCoursesByTeacherId({
        teacherId: user.teacherId,
        language: '',
        token: user.token
      }))

      setTimeout(() => navigate('/courses'), 2000)
    } catch (error) {
      console.error('Error adding course:', error)
      setStatus({ type: 'error', message: 'Failed to add course. Please try again.' })
    } finally {
      setIsLoading(false)
    }
  }

  const nextStep = () => {
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1))
  }

  const prevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0))
  }

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <CourseBasicInfo courseData={courseData} handleInputChange={handleInputChange} handleFileUpload={handleFileUpload} />
      case 1:
        return <Curriculum courseData={courseData} setCourseData={setCourseData} />
      case 2:
        return <Pricing courseData={courseData} handleInputChange={handleInputChange} />
      case 3:
        return <Promotions courseData={courseData} setCourseData={setCourseData} />
      case 4:
        return <CourseMessage courseData={courseData} handleInputChange={handleInputChange} />
      default:
        return null
    }
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-center mb-6">
        <button 
          onClick={() => navigate('/courses')} 
          className="text-purple-600 hover:text-purple-800"
        >
          ← Back to Courses
        </button>
        <button 
          onClick={handleSubmit}
          disabled={isLoading || currentStep !== steps.length - 1}
          className={`bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-300 ${(isLoading || currentStep !== steps.length - 1) ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </button>
      </div>

      <h1 className="text-2xl font-bold mb-2">Course setup</h1>
      <p className="text-gray-600 mb-6">Fill in the details below about your course</p>

      {status && (
        <div className={`mb-4 p-4 rounded-md ${status.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {status.message}
        </div>
      )}

      <div className="mb-8">
        <div className="flex justify-between">
          {steps.map((step, index) => (
            <div key={step} className={`flex flex-col items-center ${index <= currentStep ? 'text-purple-600' : 'text-gray-400'}`}>
              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${index <= currentStep ? 'bg-purple-600 text-white' : 'bg-gray-200'}`}>
                {index + 1}
              </div>
              <span className="mt-2">{step}</span>
            </div>
          ))}
        </div>
        <div className="mt-4 h-2 bg-gray-200 rounded-full">
          <div 
            className="h-full bg-purple-600 rounded-full transition-all duration-300 ease-in-out"
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
          ></div>
        </div>
      </div>

      {renderStep()}

      <div className="mt-8 flex justify-between">
        <button
          onClick={prevStep}
          disabled={currentStep === 0}
          className={`px-4 py-2 rounded-md ${currentStep === 0 ? 'bg-gray-300 text-gray-500' : 'bg-purple-600 text-white hover:bg-purple-700'} transition duration-300`}
        >
          Previous
        </button>
        <button
          onClick={nextStep}
          disabled={currentStep === steps.length - 1}
          className={`px-4 py-2 rounded-md ${currentStep === steps.length - 1 ? 'bg-gray-300 text-gray-500' : 'bg-purple-600 text-white hover:bg-purple-700'} transition duration-300`}
        >
          Next
        </button>
      </div>

      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        </div>
      )}
    </div>
  )
}

export default CourseSetup