import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getCoursesByTeacherId } from '../api'

// Create an async thunk for fetching courses
export const fetchCoursesByTeacherId = createAsyncThunk(
  'courses/fetchByTeacherId',
  async ({ teacherId, language, token }, { rejectWithValue }) => {
    try {
      const response = await getCoursesByTeacherId(teacherId, language, token)
      console.log(response);
      return response
    } catch (error) {
      return rejectWithValue(error.toString())
    }
  }
)

const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    list: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoursesByTeacherId.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCoursesByTeacherId.fulfilled, (state, action) => {
        state.loading = false
        state.list = action.payload
      })
      .addCase(fetchCoursesByTeacherId.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})

export default coursesSlice.reducer
