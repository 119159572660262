import React from 'react'

function Logo() {
  return (
    <div className="text-purple-600 font-bold text-2xl">
      EDUS
    </div>
  )
}

export default Logo
