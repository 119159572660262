import React, { useState } from 'react'
import Logo from './Logo'

function ResetPassword({ onReturnToLogin }) {
  const [emailSent, setEmailSent] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    // Here you would typically make an API call to send the reset email
    // For now, we'll just set the state to simulate the email being sent
    setEmailSent(true)
  }

  return (
    <div className="w-full max-w-md mx-auto bg-white p-8 rounded-lg shadow-md">
      <div className="text-center mb-8">
        <Logo className="mx-auto mb-4" />
        <h2 className="text-3xl font-bold mb-2">EDUS</h2>
        {!emailSent && (
          <p className="text-xl font-medium">Enter Email that connected to your account</p>
        )}
      </div>
      {!emailSent ? (
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
            <input 
              type="email" 
              id="email" 
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder="Enter your email"
              required
            />
          </div>
          <button 
            type="submit" 
            className="w-full bg-purple-600 text-white py-3 rounded-md hover:bg-purple-700 transition duration-300 text-lg font-semibold"
          >
            Reset Password →
          </button>
        </form>
      ) : (
        <div className="text-center">
          <div className="mb-6 p-4 bg-green-100 border-l-4 border-green-500 text-green-700">
            <p className="font-bold">Check your email</p>
            <p>We have sent a reset password link to your email</p>
          </div>
          <button 
            onClick={onReturnToLogin}
            className="w-full bg-purple-600 text-white py-3 rounded-md hover:bg-purple-700 transition duration-300 text-lg font-semibold"
          >
            Return to Login
          </button>
        </div>
      )}
      {!emailSent && (
        <div className="mt-6 text-center">
          <a 
            href="#" 
            className="text-purple-600 hover:underline" 
            onClick={onReturnToLogin}
          >
            Return to login page
          </a>
        </div>
      )}
    </div>
  )
}

export default ResetPassword
