import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function Dashboard({ teacherInfo }) {
  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Enrolls',
        data: [20, 60, 40, 70, 50, 10, 5, 40, 50, 0, 0, 0],
        backgroundColor: '#9708C9',
      },
      {
        label: 'Visits',
        data: [40, 50, 60, 40, 30, 20, 30, 60, 0, 0, 0, 0],
        backgroundColor: '#E6B9F7',
      },
    ],
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 20,
        },
      },
    },
  }

  if (!teacherInfo) {
    return <div>Loading teacher information...</div>
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <StatCard title="Total Students" value={teacherInfo.totalStudents} change="+5% from last month" positive={true} />
        <StatCard title="Total Courses" value={teacherInfo.totalCourses} change="N/A" positive={false} />
        <StatCard title="Total Earnings" value={`$${teacherInfo.totalEarnings}`} change="+10% from last month" positive={true} />
        <StatCard title="Average Rating" value={teacherInfo.averageRating} change="N/A" positive={false} />
      </div>
      <div className="bg-white p-6 rounded-lg shadow mb-8">
        <h2 className="text-xl font-semibold mb-4">Enrollment and Visits</h2>
        <Bar data={chartData} options={chartOptions} />
      </div>
      {/* Add more dashboard sections as needed */}
    </div>
  )
}

function StatCard({ title, value, change, positive }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h2 className="text-lg font-semibold text-gray-700 mb-2">{title}</h2>
      <p className="text-3xl font-bold">{value}</p>
      {change !== "N/A" && (
        <p className={`text-sm ${positive ? 'text-green-500' : 'text-red-500'}`}>
          {change}
        </p>
      )}
    </div>
  )
}

export default Dashboard
