import React from 'react'
import { Link } from 'react-router-dom'
import { FiGrid, FiMessageSquare, FiPlay, FiUsers, FiEye, FiBarChart2, FiRepeat, FiCreditCard, FiLayout, FiImage, FiVideo, FiHeadphones, FiSettings } from 'react-icons/fi'
import { useSelector } from 'react-redux'

function Sidebar({ darkMode }) {
  const activeComponent = useSelector(state => state.ui.activeComponent)

  const bgColor = darkMode ? 'bg-gray-800' : 'bg-white'
  const textColor = darkMode ? 'text-gray-200' : 'text-gray-600'
  const hoverBgColor = darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
  const activeColor = 'bg-purple-600 text-white'
  const headerColor = darkMode ? 'text-gray-400' : 'text-gray-400'

  return (
    <div className={`w-64 ${bgColor} h-screen shadow-lg`}>
      <div className="p-4">
        <h2 className={`text-lg font-semibold ${headerColor} mb-4`}>Main Menu</h2>
        <nav>
          <Link
            to="/"
            className={`flex items-center w-full p-2 rounded-md mb-2 ${activeComponent === 'dashboard' ? activeColor : `${textColor} ${hoverBgColor}`}`}
          >
            <FiGrid className="mr-3" />
            Dashboard
          </Link>
          <Link
            to="/messages"
            className={`flex items-center w-full p-2 rounded-md mb-2 ${activeComponent === 'messages' ? activeColor : `${textColor} ${hoverBgColor}`}`}
          >
            <FiMessageSquare className="mr-3" />
            Messages
            <span className="ml-auto bg-purple-600 text-white text-xs px-2 py-1 rounded-full">13</span>
          </Link>
        </nav>
      </div>
      <div className="p-4">
        <h2 className={`text-lg font-semibold ${headerColor} mb-4`}>Education</h2>
        <nav>
          <Link
            to="/courses"
            className={`flex items-center w-full p-2 rounded-md mb-2 ${activeComponent === 'courses' ? activeColor : `${textColor} ${hoverBgColor}`}`}
          >
            <FiPlay className="mr-3" />
            Courses
          </Link>
          <Link
            to="/students"
            className={`flex items-center w-full p-2 rounded-md mb-2 ${activeComponent === 'students' ? activeColor : `${textColor} ${hoverBgColor}`}`}
          >
            <FiUsers className="mr-3" />
            Students
          </Link>
          <Link
            to="/live-class"
            className={`flex items-center w-full p-2 rounded-md mb-2 ${activeComponent === 'liveClass' ? activeColor : `${textColor} ${hoverBgColor}`}`}
          >
            <FiEye className="mr-3" />
            Live Class
          </Link>
        </nav>
      </div>
      <div className="p-4">
        <h2 className={`text-lg font-semibold ${headerColor} mb-4`}>Financial</h2>
        <nav>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiBarChart2 className="mr-3" />
            Reports
          </a>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiRepeat className="mr-3" />
            Transactions
          </a>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiCreditCard className="mr-3" />
            Withdraw money
          </a>
        </nav>
      </div>
      <div className="p-4">
        <h2 className={`text-lg font-semibold ${headerColor} mb-4`}>Edus services</h2>
        <nav>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiLayout className="mr-3" />
            Advertising
          </a>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiImage className="mr-3" />
            Thumbnail design
          </a>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiVideo className="mr-3" />
            Recording & video edit
          </a>
        </nav>
      </div>
      <div className="p-4 mt-auto">
        <nav>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiHeadphones className="mr-3" />
            Help center
          </a>
          <a href="#" className={`flex items-center p-2 ${textColor} ${hoverBgColor} rounded-md mb-2`}>
            <FiSettings className="mr-3" />
            Settings
          </a>
        </nav>
      </div>
    </div>
  )
}

export default Sidebar
