import React from 'react'

function Pricing({ courseData, handleInputChange }) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Pricing</h2>
      <div>
        <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-2">Course Price</label>
        <input 
          type="number" 
          id="price" 
          name="price" 
          value={courseData.price} 
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          placeholder="Enter course price"
        />
      </div>
      {/* Add more pricing form elements here */}
    </div>
  )
}

export default Pricing
