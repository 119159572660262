import React, { useState } from 'react'
import Logo from './Logo'

function NewPassword({ onPasswordReset }) {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    // Here you would typically make an API call to update the password
    // For now, we'll just call the onPasswordReset function
    if (password === confirmPassword) {
      onPasswordReset()
    } else {
      alert("Passwords don't match")
    }
  }

  return (
    <div className="w-full max-w-md mx-auto bg-white p-8 rounded-lg shadow-md">
      <div className="text-center mb-8">
        <Logo className="mx-auto mb-4" />
        <h2 className="text-3xl font-bold mb-2">EDUS</h2>
        <p className="text-xl font-medium">Please enter your new password</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
          <input 
            type="password" 
            id="password" 
            className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">Confirm Password</label>
          <input 
            type="password" 
            id="confirmPassword" 
            className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-purple-600 text-white py-3 rounded-md hover:bg-purple-700 transition duration-300 text-lg font-semibold"
        >
          Confirm
        </button>
      </form>
      <div className="mt-6 text-center">
        <a 
          href="#" 
          className="text-purple-600 hover:underline" 
          onClick={onPasswordReset}
        >
          Return to login page
        </a>
      </div>
    </div>
  )
}

export default NewPassword
