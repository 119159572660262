import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom'
import Login from './components/Login'
import Signup from './components/Signup'
import ResetPassword from './components/ResetPassword'
import NewPassword from './components/NewPassword'
import Home from './components/Home'
import Courses from './components/Courses'
import CourseSetup from './components/CourseSetup'
import { setUser, logout } from './store/authSlice'
import Sidebar from './components/Sidebar'

// New component for authenticated layout
const AuthenticatedLayout = () => {
  const [darkMode, setDarkMode] = React.useState(false)
  
  return (
    <div className={`flex min-h-screen ${darkMode ? 'dark bg-gray-900' : 'bg-gray-100'}`}>
      <Sidebar darkMode={darkMode} />
      <div className="flex-1 ml-64">
        <Outlet />
      </div>
    </div>
  )
}

function App() {
  const dispatch = useDispatch()
  const { isAuthenticated, user } = useSelector((state) => state.auth)

  const handleLoginSuccess = (userData) => {
    dispatch(setUser(userData))
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <Router>
      <div className="font-sans min-h-screen bg-gray-100">
        <Routes>
          {isAuthenticated ? (
            <Route element={<AuthenticatedLayout />}>
              <Route path="/" element={<Home user={user} onLogout={handleLogout} />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/new" element={<CourseSetup />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          ) : (
            <>
              <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/new-password" element={<NewPassword />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  )
}

export default App
