import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CourseCard from './CourseCard'
import { FiSearch, FiGrid, FiList } from 'react-icons/fi'
import { fetchCoursesByTeacherId } from '../store/coursesSlice'

function Courses() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { list: coursesData, loading, error } = useSelector((state) => state.courses)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('Popularity')
  const [viewMode, setViewMode] = useState('grid')

  useEffect(() => {
    if (user && user.teacherId) {
      dispatch(fetchCoursesByTeacherId({
        teacherId: '17',
        language: '',
        token: user.token
      }))
    }
  }, [dispatch, user])

  const courses = Array.isArray(coursesData?.courses) ? coursesData.courses : []

  const filteredCourses = courses.filter(course => 
    course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const sortedCourses = [...filteredCourses].sort((a, b) => {
    if (sortBy === 'Popularity') {
      return b.numberOfSubscriptions - a.numberOfSubscriptions
    } else if (sortBy === 'Date') {
      return new Date(b.dateOfCreatingCourse) - new Date(a.dateOfCreatingCourse)
    } else if (sortBy === 'Rating') {
      return (parseFloat(b.ratingCourse) || 0) - (parseFloat(a.ratingCourse) || 0)
    }
    return 0
  })

  const handleNewCourse = () => {
    navigate('/courses/new')
  }

  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  }

  if (error) {
    return <div className="text-red-500 text-center">Error: {error}</div>
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-2">Courses</h1>
      <p className="text-gray-600 mb-6">Manage courses for your students keep them well educated</p>
      
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        <div className="relative w-full md:w-64">
          <input
            type="text"
            placeholder="Search..."
            className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        </div>
        <div className="flex space-x-4">
          <select
            className="border rounded-md p-2"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="Popularity">Sort by Popularity</option>
            <option value="Date">Sort by Date</option>
            <option value="Rating">Sort by Rating</option>
          </select>
          <button 
            className={`p-2 rounded-md ${viewMode === 'grid' ? 'bg-purple-600 text-white' : 'bg-gray-200 text-gray-600'}`}
            onClick={() => setViewMode('grid')}
          >
            <FiGrid />
          </button>
          <button 
            className={`p-2 rounded-md ${viewMode === 'list' ? 'bg-purple-600 text-white' : 'bg-gray-200 text-gray-600'}`}
            onClick={() => setViewMode('list')}
          >
            <FiList />
          </button>
          <button 
            onClick={handleNewCourse}
            className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-300"
          >
            New Course
          </button>
        </div>
      </div>

      {sortedCourses.length === 0 ? (
        <div>No courses found.</div>
      ) : (
        <div className={viewMode === 'grid' ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" : "space-y-4"}>
          {sortedCourses.map(course => (
            <CourseCard key={course.courseId} course={course} viewMode={viewMode} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Courses
