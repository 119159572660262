import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Logo from './Logo'
import Alert from './Alert'
import { loginForTeacher } from '../api'
import { setUser } from '../store/authSlice'

function Login({ onSwitchToSignup, onForgotPassword }) {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [alert, setAlert] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await loginForTeacher(email, password)
      if (response.status === 'success') {
        setAlert({ type: 'success', message: 'Login successful!' })
        dispatch(setUser(response.data))
      } else {
        setAlert({ type: 'error', message: response.message })
      }
    } catch (error) {
      setAlert({ type: 'error', message: 'An error occurred. Please try again.' })
    }
  }

  return (
    
    <div className="flex w-full max-w-6xl bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="w-1/2 p-12 relative">
        <Logo className="absolute top-8 left-12" />
        <div className="mt-16 mb-8">
          <h2 className="text-3xl font-bold mb-2">Welcome Back,</h2>
          <p className="text-gray-500">Please enter your login information</p>
        </div>
        {alert && (
          <Alert
            type={alert.type}
            message={alert.message}
            onClose={() => setAlert(null)}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
            <input 
              type="email" 
              id="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" 
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
            <input 
              type="password" 
              id="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" 
            />
          </div>
          <div className="mb-6 text-right">
            <a href="#" className="text-purple-600 hover:underline" onClick={onForgotPassword}>Forgot your password?</a>
          </div>
          <button type="submit" className="w-full bg-purple-600 text-white py-3 rounded-md hover:bg-purple-700 transition duration-300 text-lg font-semibold">
            Login →
          </button>
        </form>
        <div className="mt-8 flex items-center justify-between">
          <hr className="w-full border-gray-300" />
          <span className="px-4 text-gray-500 bg-white">or</span>
          <hr className="w-full border-gray-300" />
        </div>
        <div className="mt-6 space-y-4">
          <button className="w-full flex items-center justify-center bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 transition duration-300">
            <svg className="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
            Continue with Facebook
          </button>
          <button className="w-full flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-3 rounded-md hover:bg-gray-50 transition duration-300">
            <svg className="w-6 h-6 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z" fill="#4285F4"/><path d="M0 0h24v24H0z" fill="none"/></svg>
            Continue with Google
          </button>
          <button className="w-full flex items-center justify-center bg-black text-white py-3 rounded-md hover:bg-gray-800 transition duration-300">
            <svg className="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm5.5-15.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5S19 8.828 19 8s-.672-1.5-1.5-1.5zm-11 0C5.672 6.5 5 7.172 5 8s.672 1.5 1.5 1.5S8 8.828 8 8s-.672-1.5-1.5-1.5zm5.5 4c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"/></svg>
            Continue with Apple
          </button>
        </div>
        <div className="mt-8 text-center">
          <span className="text-gray-600">You do not have an account? </span>
          <a href="#" className="text-purple-600 hover:underline font-semibold" onClick={onSwitchToSignup}>Create an account</a>
        </div>
      </div>
      <div className="w-1/2 bg-purple-600 p-12 flex flex-col justify-between">
        <div className="flex justify-end">
          <div className="w-10 h-10 bg-white rounded-md mr-3"></div>
          <div className="w-10 h-10 bg-white rounded-md mr-3"></div>
          <div className="w-10 h-10 bg-white rounded-md"></div>
        </div>
        <div className="text-white">
          <h2 className="text-4xl font-bold mb-6">Provide superior educational content to your students through our platform</h2>
          <p className="text-xl">Our platform helps you deliver your teaching method to a large audience that will surely love it</p>
        </div>
        <div className="flex justify-center">
          <div className="w-3 h-3 bg-white rounded-full mx-1"></div>
          <div className="w-3 h-3 bg-white bg-opacity-50 rounded-full mx-1"></div>
          <div className="w-3 h-3 bg-white bg-opacity-50 rounded-full mx-1"></div>
        </div>
      </div>
    </div>
  )
}

export default Login
