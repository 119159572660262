import React from 'react'

function Promotions({ courseData, setCourseData }) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Promotions</h2>
      <p>Set up course promotions here.</p>
      {/* Add promotions form elements here */}
    </div>
  )
}

export default Promotions
