import axios from 'axios';

const API_URL = 'https://edusapp.com';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// Auth
export const signupForTeacher = async (formData) => {
  try {
    const response = await api.post('/dashboard/auth/signupForTeacher.php', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const loginForTeacher = async (email, password, language = '') => {
  const formData = new FormData();
  formData.append('identifier', email); // or 'email' if that's what the API expects
  formData.append('password', password);
  formData.append('language', language);

  try {
    const response = await api.post('/dashboard/auth/loginForTeacher.php', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const editInfoProfile = async (formData) => {
  try {
    const response = await api.post('/dashboard/auth/editInfoProfile.php', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Courses
export const addCourse = async (formData) => {
  try {
    const response = await api.post('/dashboard/courses/addCourse.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateCourse = async (formData) => {
  try {
    const response = await api.post('/dashboard/courses/updateCourse.php', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getCoursesByTeacherId = async (teacherId, language, token) => {
  const formData = new FormData();
  formData.append('teacherId', teacherId);
  formData.append('language', language);
  formData.append('token', 'PNUxAAWbRgyfz1UEYgRjsIV8hwVrHiv5LOLh2KggCLo7WYlsdAG3TTXortvUAKVkizpnOl0SZbiQBvy4swYVM322TqV9vR3jAtweGfZlcw0xlkeHv6wRr6hRNOe1NXwIlbysVQ6OUYtP4ak5Jqarif');

  try {
    const response = await api.post('/dashboard/courses/getCoursesByTeacherId.php', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Forgot Password
export const forgotPassword = async (email, language = '') => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('language', language);

  try {
    const response = await api.post('/forgotPassword/forgotPassword.php', formData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Info Teacher
export const getInfoTeacher = async (teacherId, language = '', startDate, endDate, token) => {
  const formData = new FormData();
  formData.append('teacherId', teacherId);
  formData.append('language', '');
  formData.append('startDate', startDate);
  formData.append('endDate', endDate);
  formData.append('token', token);

  try {
    const response = await api.post('/dashboard/info/infoTeacher.php', formData);
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Subjects
export const viewSubjects = async () => {
  try {
    const response = await api.post('/dashboard/subjects/viewSubjects.php');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export default api;