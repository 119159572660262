import React from 'react'

function CourseMessage({ courseData, handleInputChange }) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Course Message</h2>
      <div>
        <label htmlFor="courseMessage" className="block text-sm font-medium text-gray-700 mb-2">Message to Students</label>
        <textarea 
          id="courseMessage" 
          name="courseMessage" 
          value={courseData.courseMessage} 
          onChange={handleInputChange}
          rows="4"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          placeholder="Enter a message for your students"
        ></textarea>
      </div>
    </div>
  )
}

export default CourseMessage
