import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  isAuthenticated: false,
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.token = 'PNUxAAWbRgyfz1UEYgRjsIV8hwVrHiv5LOLh2KggCLo7WYlsdAG3TTXortvUAKVkizpnOl0SZbiQBvy4swYVM322TqV9vR3jAtweGfZlcw0xlkeHv6wRr6hRNOe1NXwIlbysVQ6OUYtP4ak5Jqarif';
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
    },
  },
});

export const { setUser, logout } = authSlice.actions;

export default authSlice.reducer;
