import React from 'react'

function Alert({ type, message, onClose }) {
  const bgColor = type === 'success' ? 'bg-green-100' : 'bg-red-100'
  const textColor = type === 'success' ? 'text-green-700' : 'text-red-700'
  const borderColor = type === 'success' ? 'border-green-500' : 'border-red-500'

  return (
    <div className={`${bgColor} border-l-4 ${borderColor} ${textColor} p-4 mb-4 relative`} role="alert">
      <p className="font-bold">{type === 'success' ? 'Success' : 'Error'}</p>
      <p>{message}</p>
      <button
        onClick={onClose}
        className="absolute top-0 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
        aria-label="Close"
      >
        <span className="text-2xl">&times;</span>
      </button>
    </div>
  )
}

export default Alert
