import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getInfoTeacher } from '../api';

export const fetchTeacherInfo = createAsyncThunk(
  'teacherInfo/fetchTeacherInfo',
  async ({ teacherId, token }, thunkAPI) => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
    const endDate = today.toISOString().split('T')[0];
    try {
      const response = await getInfoTeacher(teacherId, '', startDate, endDate, token);
      console.log(teacherId, startDate, endDate, token);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue('Failed to fetch teacher info');
    }
  }
);

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const teacherInfoSlice = createSlice({
  name: 'teacherInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeacherInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTeacherInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTeacherInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default teacherInfoSlice.reducer;
