import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Logo from './Logo'
import { FiSearch, FiMoon, FiSun, FiBell, FiChevronDown } from 'react-icons/fi'
import { fetchTeacherInfo } from '../store/teacherInfoSlice'
import Courses from './Courses'
import Dashboard from './Dashboard'

function Home({ onLogout }) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { data: teacherInfo, loading, error } = useSelector((state) => state.teacherInfo)
  const [darkMode, setDarkMode] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const activeComponent = useSelector((state) => state.ui.activeComponent)

  useEffect(() => {
    if (user && user.teacherId) {
      dispatch(fetchTeacherInfo({ teacherId: user.teacherId, token: user.token }))
    }
  }, [dispatch, user])

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [darkMode])

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
  }

  const renderActiveComponent = () => {
    if (loading) {
      return <div>Loading...</div>
    }

    if (error) {
      return <div>Error: {error}</div>
    }

    switch (activeComponent) {
      case 'courses':
        return <Courses />
      case 'dashboard':
      default:
        return <Dashboard teacherInfo={teacherInfo} />
    }
  }

  return (
    <>
      <header className={`${darkMode ? 'bg-gray-800' : 'bg-white'} shadow-sm`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <Logo className="w-12 h-12" />
            <nav className="flex space-x-4">
              <a href="#" className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-500 hover:text-gray-700'}`}>Main Menu</a>
              <a href="#" className={`${darkMode ? 'text-white' : 'text-gray-900'} font-medium`}>{activeComponent.charAt(0).toUpperCase() + activeComponent.slice(1)}</a>
            </nav>
          </div>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className={`pl-10 pr-4 py-2 border ${darkMode ? 'bg-gray-700 border-gray-600 text-white' : 'bg-white border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600`}
              />
              <FiSearch className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-400'}`} />
            </div>
            <button 
              className={`${darkMode ? 'text-yellow-300 hover:text-yellow-400' : 'text-gray-600 hover:text-gray-800'}`}
              onClick={toggleDarkMode}
            >
              {darkMode ? <FiSun size={20} /> : <FiMoon size={20} />}
            </button>
            <button className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-800'}`}>
              <FiBell size={20} />
            </button>
            <button className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-300">
              New Course
            </button>
            <div className="flex items-center space-x-2">
              <img src={user.avatar} alt={user.name} className="w-8 h-8 rounded-full" />
              <div className="relative">
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className="flex items-center focus:outline-none"
                >
                  <FiChevronDown className={`ml-1 ${darkMode ? 'text-white' : 'text-gray-700'}`} />
                </button>
                {showMenu && (
                  <div className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'} ring-1 ring-black ring-opacity-5`}>
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <button
                        onClick={() => {/* Implement info action */}}
                        className={`block px-4 py-2 text-sm ${darkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'} w-full text-left`}
                        role="menuitem"
                      >
                        Info
                      </button>
                      <button
                        onClick={onLogout}
                        className={`block px-4 py-2 text-sm ${darkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'} w-full text-left`}
                        role="menuitem"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col">
                <span className={`${darkMode ? 'text-white' : 'text-gray-700'} font-semibold`}>{user.fullName}</span>
                <span className={`${darkMode ? 'text-gray-400' : 'text-gray-500'} text-sm`}>{user.email}</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="p-6">
        {renderActiveComponent()}
      </main>
    </>
  )
}

export default Home
