import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import teacherInfoReducer from './teacherInfoSlice';
import uiReducer from './uiSlice';
import coursesReducer from './coursesSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    teacherInfo: teacherInfoReducer,
    ui: uiReducer,
    courses: coursesReducer,
  },
});

export { store };
export default store;
