import React from 'react'
import { FiUsers, FiClock, FiStar } from 'react-icons/fi'

function CourseCard({ course, viewMode }) {
  return (
    <div className={`bg-white rounded-lg shadow-md overflow-hidden ${viewMode === 'list' ? 'flex' : ''}`}>
      <div className={`h-48 bg-gray-300 ${viewMode === 'list' ? 'w-64 flex-shrink-0' : ''}`}>
        <img src={course.courseImage} alt={course.courseName} className="w-full h-full object-cover" />
      </div>
      <div className="p-4 flex-1">
        <span className="bg-purple-100 text-purple-600 text-xs px-2 py-1 rounded-full">
          {course.subjectName}
        </span>
        <h2 className="text-lg font-semibold mt-2">{course.courseName}</h2>
        <p className="text-gray-600 text-sm mt-1">{course.description}</p>
        <div className="flex justify-between items-center mt-4">
          <div className="flex items-center space-x-4">
            <span className="flex items-center">
              <FiUsers className="text-gray-400 mr-1" />
              {course.numberOfSubscriptions}
            </span>
            <span className="flex items-center">
              <FiClock className="text-gray-400 mr-1" />
              {course.numberOfLectures} lectures
            </span>
            <span className="flex items-center">
              <FiStar className="text-yellow-400 mr-1" />
              {course.ratingCourse || 'N/A'}
            </span>
          </div>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <span className="text-lg font-bold text-purple-600">${course.price}</span>
          <button className="bg-purple-600 text-white px-4 py-2 rounded-md hover:bg-purple-700 transition duration-300">
            View Details
          </button>
        </div>
      </div>
    </div>
  )
}

export default CourseCard
