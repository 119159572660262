import React from 'react'
import Logo from './Logo'

function Signup({ onSwitchToLogin }) {
  return (
    <div className="flex w-full max-w-4xl bg-white rounded-lg shadow-lg overflow-hidden relative">
      <Logo />
      <div className="w-1/2 p-8">
        <div className="mb-8">
          <h2 className="text-3xl font-semibold mb-2">Create your account</h2>
          <p className="text-gray-600 font-normal">Enter your credentials to access your account</p>
        </div>
        <form>
          <div className="mb-4">
            <label htmlFor="fullName" className="block text-gray-700 mb-2">Full Name</label>
            <input type="text" id="fullName" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
            <input type="email" id="email" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" />
          </div>
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block text-gray-700 mb-2">Phone Number</label>
            <input type="tel" id="phoneNumber" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" />
          </div>
          <div className="mb-4">
            <label htmlFor="location" className="block text-gray-700 mb-2">Location</label>
            <div className="flex space-x-4">
              <input type="text" id="state" placeholder="State" className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" />
              <input type="text" id="city" placeholder="City" className="w-1/2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
            <input type="password" id="password" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" />
          </div>
          <div className="mb-6">
            <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">Confirm Password</label>
            <input type="password" id="confirmPassword" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" />
          </div>
          <button type="submit" className="w-full bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700 transition duration-300">Sign Up →</button>
        </form>
        <div className="mt-6 flex items-center justify-between">
          <hr className="w-full border-gray-300" />
          <span className="px-2 text-gray-500 bg-white">or</span>
          <hr className="w-full border-gray-300" />
        </div>
        <div className="mt-4 space-y-3">
          <button className="w-full flex items-center justify-center bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
            Continue with Facebook
          </button>
          <button className="w-full flex items-center justify-center bg-blue-400 text-white py-2 rounded-md hover:bg-blue-500 transition duration-300">
            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
            Continue with Twitter
          </button>
          <button className="w-full flex items-center justify-center bg-white text-gray-700 border border-gray-300 py-2 rounded-md hover:bg-gray-50 transition duration-300">
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z" fill="#4285F4"/><path d="M0 0h24v24H0z" fill="none"/></svg>
            Continue with Google
          </button>
        </div>
        <div className="mt-6 text-center">
          <span className="text-gray-600">Already have an account? </span>
          <a href="#" className="text-purple-600 hover:underline" onClick={onSwitchToLogin}>Login</a>
        </div>
      </div>
      <div className="w-1/2 bg-purple-600 p-8 flex flex-col justify-between">
        <div className="flex justify-end">
          <div className="w-8 h-8 bg-white rounded-md mr-2"></div>
          <div className="w-8 h-8 bg-white rounded-md mr-2"></div>
          <div className="w-8 h-8 bg-white rounded-md"></div>
        </div>
        <div className="text-white">
          <h2 className="text-3xl font-bold mb-4">Provide superior educational content to your students through our platform</h2>
          <p>Our platform helps you deliver your teaching method to a large audience that will surely love it</p>
        </div>
        <div className="flex justify-center">
          <div className="w-2 h-2 bg-white rounded-full mx-1"></div>
          <div className="w-2 h-2 bg-white bg-opacity-50 rounded-full mx-1"></div>
          <div className="w-2 h-2 bg-white bg-opacity-50 rounded-full mx-1"></div>
        </div>
      </div>
    </div>
  )
}

export default Signup
