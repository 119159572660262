import React from 'react'

function Curriculum({ courseData, setCourseData }) {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-4">Curriculum</h2>
      <p>Add your course curriculum here.</p>
      {/* Add curriculum form elements here */}
    </div>
  )
}

export default Curriculum
